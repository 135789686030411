import { FunctionComponent, useState } from 'react';
import PostenHeader from '@common/header/PostenHeader';
import HeaderLeftContent from '@common/header/HeaderLeftContent';
import HeaderRightContent from '@common/header/HeaderRightContent';
import Hamburger from '@common/header/Hamburger/Hamburger';
import HamburgerDropdownMenu from '@common/header/Hamburger/HamburgerDropdownMenu';
import HamburgerDropdownLink from '@common/header/Hamburger/HamburgerDropdownLink';
import { useI18n } from '@common/language/i18n';
import HeaderDropDown from './HeaderDropDown';
import {
  contactItems,
  organizationItems,
  privatePersonItems,
} from './headerDropdowns';
import HamburgerElement from '@common/header/Hamburger/HamburgerElement';
import LoginModal from './login/LoginModal';
import { FRONTPAGE_CLICK } from '../organization/constants';
import { useLocation } from 'react-router-dom';
import { AppName } from '@common/analytics/appNames';
import { HelpTopicUrl } from '@common/help/helpTopic';
import { App } from '../apps';
import { useTracking } from '@common/analytics/Analytics';
import {
  ABOUT_DIGITAL_SIGNATURES_URL,
  DIFI_HELP_URL,
} from '@common/help/helpUrls';

interface LoggedOutHeaderProps {
  app: App;
  defaultHelpTopic?: string;
}

const LoggedOutHeader: FunctionComponent<LoggedOutHeaderProps> = ({
  app,
  defaultHelpTopic,
}) => {
  const [translation] = useI18n();
  const [expanded, setExpanded] = useState(false);
  const [expandedDropdown, setExpandedDropdown] = useState<string>();
  const location = useLocation();
  const { registerAnalyticsEvent } = useTracking();

  const expandDropdown = (value: boolean, name: string) => {
    if (value) {
      setExpandedDropdown(name);
    } else if (expandedDropdown === name) {
      setExpandedDropdown(undefined);
    }
  };

  return (
    <PostenHeader homeLink="/">
      <HeaderLeftContent>
        <HeaderDropDown
          buttonText={translation.common.frontpage.menu.private}
          active={
            app === App.PRIVATE_PERSON &&
            !location.pathname.includes('hjelp') &&
            !window.location.href.includes('priser')
          }
          listItems={privatePersonItems(translation.common.frontpage.menu)}
          setExpanded={(value: boolean) => {
            expandDropdown(value, 'privat');
          }}
          expanded={expandedDropdown === 'privat'}
          name="privat"
          dataTestid="landing-navbar-person-dropdown"
        />
        <HeaderDropDown
          buttonText={translation.common.frontpage.menu.organization}
          active={
            app === App.ORGANIZATION_PORTAL &&
            !location.pathname.includes('hjelp') &&
            !window.location.href.includes('priser')
          }
          listItems={organizationItems(translation.common.frontpage.menu)}
          setExpanded={(value: boolean) => {
            expandDropdown(value, 'organization');
          }}
          expanded={expandedDropdown === 'organization'}
          name="organization"
          dataTestid="landing-navbar-organization-dropdown"
        />
        <a
          href="/priser"
          data-testid="landing-navbar-prices-button"
          onClick={() =>
            registerAnalyticsEvent({
              category: 'Landingsside',
              action: FRONTPAGE_CLICK,
              label: `Trykket på ${translation.common.frontpage.prices.heading}`,
            })
          }
          className={`navbar__menu-item navbar__menu_item-button ${
            window.location.href.includes('priser')
              ? 'navbar__menu-item--active'
              : ''
          }`}
        >
          {translation.common.frontpage.prices.heading}
        </a>
        <HeaderDropDown
          buttonText={translation.common.frontpage.menu.help}
          active={location.pathname.includes('hjelp')}
          listItems={contactItems(translation.common.frontpage.help)}
          setExpanded={(value: boolean) => {
            expandDropdown(value, 'help');
          }}
          expanded={expandedDropdown === 'help'}
          name="help"
          dataTestid="landing-navbar-help-dropdown"
        />
      </HeaderLeftContent>
      <HeaderRightContent
        loggedIn={false}
        app={app}
        goToDifiHelp={false}
        linkToDefaultTopic={defaultHelpTopic}
        appName={AppName.PRIVATE}
      />
      <Hamburger>
        <HamburgerElement>
          <button
            id="header-login-button-hamburger"
            className="posten-sans-bold hamburger__list-element__button hamburger__list-item__button-text"
            onClick={() => setExpanded(true)}
            type="button"
          >
            {translation.login.short}
          </button>
          <LoginModal
            expanded={expanded}
            setExpanded={setExpanded}
            ariaLabelledby="header-login-button-hamburger"
          />
        </HamburgerElement>
        <HamburgerDropdownMenu text={translation.common.frontpage.menu.private}>
          <HamburgerDropdownLink
            text={translation.common.frontpage.menu.private_dropdown.send}
            url="/privat"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.private_dropdown.how_to_send
            }
            url="/privat/slik-sender-du"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.private_dropdown
                .contract_templates
            }
            url="/privat/kontrakter"
          />
        </HamburgerDropdownMenu>
        <HamburgerDropdownMenu
          text={translation.common.frontpage.menu.organization}
        >
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.organization_dropdown
                .information
            }
            url="/virksomhet"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.organization_dropdown
                .send_from_web
            }
            url="/virksomhet/send-fra-nett"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.organization_dropdown
                .send_via_partner
            }
            url="/virksomhet/fagsystem"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.organization_dropdown
                .send_via_integration
            }
            url="/virksomhet/api"
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.menu.organization_dropdown
                .long_term_validation
            }
            url="/virksomhet/lagring"
          />
        </HamburgerDropdownMenu>
        <HamburgerElement>
          <a
            href="/priser"
            className="posten-sans-bold hamburger__list-element__button hamburger__list-item__button-text"
          >
            {translation.common.frontpage.prices.heading}
          </a>
        </HamburgerElement>
        <HamburgerDropdownMenu text={translation.common.frontpage.menu.help}>
          <HamburgerDropdownLink
            text={translation.common.frontpage.help.faq.heading}
            url={`/hjelp/${HelpTopicUrl.aboutPosten}`}
          />
          <HamburgerDropdownLink
            text={translation.common.frontpage.help.digital_signatures.heading}
            url={`/hjelp/${ABOUT_DIGITAL_SIGNATURES_URL}`}
          />
          <HamburgerDropdownLink
            text={
              translation.common.frontpage.help.public_organizations.heading
            }
            url={DIFI_HELP_URL}
          />
        </HamburgerDropdownMenu>
      </Hamburger>
    </PostenHeader>
  );
};

export default LoggedOutHeader;
