import { Navigate, Outlet } from 'react-router-dom';
import { FunctionComponent } from 'react';

import { useSession } from '../session/AppWithSession';
import { Session } from '../session/session';

const RedirectIfLoggedIn: FunctionComponent<{
  to: string;
  isLoggedIn: (session: Session) => boolean;
}> = ({ to, isLoggedIn }) => {
  const { session } = useSession();

  if (isLoggedIn(session)) {
    return <Navigate replace to={to} />;
  }

  return <Outlet />;
};

export default RedirectIfLoggedIn;
