import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import privateSectorIllustration from '@images/login/private_sector.svg';
import { useTracking } from '../analytics/Analytics';
import CardWithIllustration from '@hedwig/cards/CardWithIllustration';
import { useI18n } from '../language/i18n';
import './login.less';
import { App } from '../../apps';

interface PrivateLoginOptionProps {
  headingText: string;
  exampleText: string;
  buttonText: string;
  app: App;
}

const PrivateLoginOption: FunctionComponent<PrivateLoginOptionProps> = ({
  headingText,
  exampleText,
  buttonText,
  app,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <Link
      id="login-via-signicat-link"
      to="/logginn/privat"
      className="hw-card login-option"
      data-testid="private-login-button"
      onClick={() =>
        registerAnalyticsEvent({
          category: app,
          action: 'Klikket på logg inn med elektronisk ID',
        })
      }
    >
      <CardWithIllustration
        title={headingText}
        illustrationSource={privateSectorIllustration}
        description={exampleText}
        linkText={buttonText}
        illustrationText={
          translation.common.login.private_sector_illustration_alt_text
        }
        extraWrapperClassName="login-option__container"
        illustrationWrapperClassName="login-option__illustration-container"
        illustrationClassName="login-option__illustration"
      />
    </Link>
  );
};

export default PrivateLoginOption;
