import { FunctionComponent } from 'react';
import IDPortenLink from '../idporten/IDPortenLink';
import publicSectorIllustration from '@images/login/public_sector.svg';
import { SecurityLevel } from '../idporten/securityLevel';
import { Target } from '../session/session';
import CardWithIllustration from '@hedwig/cards/CardWithIllustration';
import { useI18n } from '../language/i18n';
import './login.less';
import { App } from '../../apps';

interface IDPortenLoginOptionProps {
  target: Target;
  app: App;
  headingText: string;
  exampleText: string;
  buttonText: string;
  securityLevel: SecurityLevel;
}

const IDPortenLoginOption: FunctionComponent<IDPortenLoginOptionProps> = ({
  target,
  app,
  headingText,
  exampleText,
  buttonText,
  securityLevel,
}) => {
  const [translation] = useI18n();

  return (
    <IDPortenLink
      target={target}
      app={app}
      securityLevel={securityLevel}
      className="hw-card login-option"
    >
      <CardWithIllustration
        title={headingText}
        illustrationSource={publicSectorIllustration}
        illustrationText={
          translation.common.login.public_sector_illustration_alt_text
        }
        description={exampleText}
        linkText={buttonText}
        extraWrapperClassName="login-option__container"
        illustrationWrapperClassName="login-option__illustration-container"
        illustrationClassName="login-option__illustration"
      />
    </IDPortenLink>
  );
};

export default IDPortenLoginOption;
