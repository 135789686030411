import { FunctionComponent } from 'react';
import { useTracking } from '../Analytics';
import {
  ButtonStyledAsLink,
  PrimaryButton,
  SecondaryButton,
} from '@hedwig/buttons';
import { useI18n } from '../../language/i18n';

import './cookie-banner.less';

const CookieBanner: FunctionComponent = () => {
  const [translation] = useI18n();
  const {
    isConsentSaved,
    saveConsentAndUpdateTracking,
    setShowConsentDialog,
    canShowCookieBanner,
  } = useTracking();

  return !isConsentSaved && canShowCookieBanner ? (
    <section
      className="cookie-banner hw-block--pb-large hw-background-color-gray-light"
      aria-label="Cookie banner"
    >
      <div className="hw-block--px hw-block--mt">
        <p className="hw-text-small cookie-banner-text">
          {translation.portal.cookie_banner.text}
        </p>
      </div>
      <div className="hw-block--px hw-block--mt">
        <div className="cookie-banner-buttons">
          <PrimaryButton
            extraClassName="hw-button--mobile-full cookie-banner-button"
            onClick={() => {
              saveConsentAndUpdateTracking({
                marketing: true,
                analytics: true,
              });
            }}
          >
            {translation.portal.cookie_banner.approve}
          </PrimaryButton>
          <SecondaryButton
            extraClassName="hw-button--mobile-full cookie-banner-button"
            onClick={() => {
              saveConsentAndUpdateTracking({
                marketing: false,
                analytics: false,
              });
            }}
          >
            {translation.portal.cookie_banner.approve_necessary}
          </SecondaryButton>
          <ButtonStyledAsLink
            extraClassName="cookie-banner-link"
            onClick={() => {
              setShowConsentDialog(true);
            }}
          >
            {translation.portal.cookie_banner.settings}
          </ButtonStyledAsLink>
        </div>
      </div>
    </section>
  ) : null;
};

export default CookieBanner;
