import { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SecurityLevel } from './securityLevel';
import { useI18n } from '../language/i18n';
import { SessionStatus } from '../session/session';
import { useSession } from '../session/AppWithSession';
import Spinner from '../basics/Spinner';

const IdPortenRedirect: FunctionComponent<IdPortenRedirectProps> = ({
  target,
  securityLevel,
}) => {
  const params = useParams<{ securityLevel: string }>();
  const [, , , , language] = useI18n();
  const { session } = useSession();
  const notLoggedIn = useMemo(
    () => session.status === SessionStatus.LOGGED_OUT,
    [session]
  );

  useEffect(() => {
    if (notLoggedIn) {
      const actualSecurityLevel =
        securityLevel || params.securityLevel || SecurityLevel.FOUR;
      window.location.replace(
        `/web/idporten?target=${target}&lang=${language}&securityLevel=${actualSecurityLevel}`
      );
    }
  });

  return <Spinner fullScreen={true} />;
};

interface IdPortenRedirectProps {
  target: string;
  securityLevel?: SecurityLevel;
}

export default IdPortenRedirect;
