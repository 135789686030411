import { HelpTopicUrl } from '@common/help/helpTopic';
import {
  ABOUT_DIGITAL_SIGNATURES_URL,
  DIFI_HELP_URL,
} from '@common/help/helpUrls';
import { Translation } from '@common/language/i18n';

export const contactItems = (
  translation: Translation['common']['frontpage']['help']
) => [
  {
    text: translation.faq.heading,
    href: `/hjelp/${HelpTopicUrl.aboutPosten}`,
  },
  {
    text: translation.digital_signatures.heading,
    href: `/hjelp/${ABOUT_DIGITAL_SIGNATURES_URL}`,
  },
  {
    text: translation.public_organizations.heading,
    href: DIFI_HELP_URL,
    openInNewTab: true,
  },
];

export const privatePersonItems = (
  translation: Translation['common']['frontpage']['menu']
) => [
  {
    text: translation.private_dropdown.send,
    href: '/privat',
  },
  {
    text: translation.private_dropdown.how_to_send,
    href: '/privat/slik-sender-du',
  },
  {
    text: translation.private_dropdown.contract_templates,
    href: '/privat/kontrakter',
  },
];

export const organizationItems = (
  translation: Translation['common']['frontpage']['menu']
) => [
  {
    text: translation.organization_dropdown.information,
    href: '/virksomhet',
  },
  {
    text: translation.organization_dropdown.send_from_web,
    href: '/virksomhet/send-fra-nett',
  },
  {
    text: translation.organization_dropdown.send_via_partner,
    href: '/virksomhet/fagsystem',
  },
  {
    text: translation.organization_dropdown.send_via_integration,
    href: '/virksomhet/api',
  },
  {
    text: translation.organization_dropdown.long_term_validation,
    href: '/virksomhet/lagring',
  },
];
