import { FunctionComponent } from 'react';
import { useHamburgerContext } from '../PostenHeader';

interface HamburgerDropdownLinkProps {
  text: string;
  url: string;
}

const HamburgerDropdownLink: FunctionComponent<HamburgerDropdownLinkProps> = ({
  text,
  url,
}) => {
  const { setHamburgerVisibility } = useHamburgerContext();
  return (
    <li className="hamburger__list-element__curtain-content__item">
      <a
        onClick={() => setHamburgerVisibility(false)}
        className="hamburger__list-element__curtain-content__item-text"
        href={url}
      >
        {text}
      </a>
    </li>
  );
};

export default HamburgerDropdownLink;
