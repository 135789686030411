import { FunctionComponent } from 'react';
import { addClassesFromProps } from '@common/basicFunctions';

interface CardWithIllustrationProps {
  title: string;
  description: string;
  linkText: string;
  illustrationSource: string;
  illustrationText: string;
  extraWrapperClassName?: string;
  extraBodyClassName?: string;
  illustrationClassName?: string;
  illustrationWrapperClassName?: string;
}

const CardWithIllustration: FunctionComponent<CardWithIllustrationProps> = ({
  title,
  description,
  linkText,
  illustrationSource,
  illustrationText,
  extraWrapperClassName,
  extraBodyClassName,
  illustrationClassName,
  illustrationWrapperClassName,
}) => {
  return (
    <div className={extraWrapperClassName}>
      <div
        className={`hw-card__media hw-card__media-16_9${addClassesFromProps(
          illustrationWrapperClassName
        )}`}
      >
        <img
          src={illustrationSource}
          className={`hw-card__media-inner${addClassesFromProps(
            illustrationClassName
          )}`}
          alt={illustrationText}
        />
      </div>
      <div
        className={`hw-card__body${addClassesFromProps(extraBodyClassName)}`}
      >
        <h2 className="hw-card__title">{title}</h2>
        <p className="hw-card__description hide--mobile">{description}</p>
      </div>
      <div className="hw-card__footer hide--mobile">
        <span className="hw-link hw-link--small">{linkText}</span>
      </div>
    </div>
  );
};

export default CardWithIllustration;
