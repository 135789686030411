import { Link } from 'react-router-dom';
import { FunctionComponent } from 'react';

import SessionTimeout from './SessionTimeout';
import { useI18n } from '../language/i18n';

const SessionTimeoutPortal: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <SessionTimeout>
      <Link className="hw-button hw-button--primary button-wide" to="/logginn/">
        {translation.login.short}
      </Link>
    </SessionTimeout>
  );
};

export default SessionTimeoutPortal;
