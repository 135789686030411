import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '../language/i18n';
import Heading from '../pageBuildingBlocks/Heading';
import {
  bankIDLogin,
  buyPassLogin,
  mobilBankIDLogin,
} from '../electronic-id/electronicIdConstants';
import ElectronicIdProviderList from '../electronic-id/ElectronicIdProviderList';

const PrivateLogin: FunctionComponent = () => {
  const navigate = useNavigate();
  const [translation] = useI18n();

  return (
    <div className="content-wrapper content-wrapper-first content-wrapper-tight">
      <Heading
        headerText={translation.common.electronic_id.choose_electronic_id}
        extraClassName="hw-block--mb-large-1 heading-center"
      />
      <ElectronicIdProviderList
        onClick={navigate}
        electronicProviders={[
          mobilBankIDLogin(translation.common.electronic_id.eid),
          bankIDLogin(translation.common.electronic_id.eid),
          buyPassLogin(translation.common.electronic_id.eid),
        ]}
      />
    </div>
  );
};

export default PrivateLogin;
