import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import SignicatIframe from '../signicat/SignicatIframe';
import { useI18n } from '../language/i18n';
import Heading from '../pageBuildingBlocks/Heading';
import { useRequiredParams } from '../routing/useRequiredParams';
import { useSession } from '../session/AppWithSession';

const PrivateLoginProcess: FunctionComponent<PrivateLoginProcessProps> = ({
  target,
}) => {
  const navigate = useNavigate();
  const [translation, , , , language] = useI18n();
  const { eID } = useRequiredParams(['eID']);
  const { refresh } = useSession();

  return (
    <div className="private-login__electronic-id-iframe-wrapper full-height full-width hw-block--mt-medium-4 hw-block--mb-medium-4">
      <Heading
        headerText={
          translation.common.electronic_id.eid[
            `${eID as 'mobile_bankid' | 'bankid' | 'buypass'}_login_heading`
          ]
        }
        extraClassName="heading-center"
        sizeClass="hw-h2"
      />
      <SignicatIframe
        successCallback={() => {
          void refresh();
        }}
        failureCallback={() => navigate('..')}
        signicatIframeLink={`/web/signicat?target=${target}&eid=${eID.toUpperCase()}&lang=${language}`}
      />
    </div>
  );
};

interface PrivateLoginProcessProps {
  target: string;
}

export default PrivateLoginProcess;
